:focus {
    outline: 0;
    outline: none;
}
.tabbed {
    outline: #f07066 solid 1px;
}
.nav {
    width: 100% !important;
    padding: 3.25vw 6.1% 0;
}
.sticky.block, .clubSticky.show {
    z-index: 2;
}
.sticky {
    /*display: none;*/
    height: 22.2vw;
    padding: 25px 0;
    width: 100%;
    background-color: #BCE1F3;
    opacity: 0;
    transition: opacity 500ms;
    position: absolute;
}
.sticky.block {
    /*display: flex;*/
    flex-direction: column;
    opacity: 1;
}
.sticky > div:first-child {
    color: #20507E;
    font-family: 'ProximaNova-SemiBold'/*rtl:"NarkisBlockMFW-Medium"*/;
    font-size: 1.25vw;
    text-align: center;
}
.sticky .iconsRow{
    display: flex;
    justify-content: space-between;
    width: 75%;
    margin: auto;
}
.sticky .iconsRow img{
    width: 2.45vw;
}
.sticky .iconsRow > div{
    width: 43%;
    text-align: center;  
    display: flex;
    flex-direction: column;
    align-items: center; 
    position: relative;
}
.sticky .tooltiptext {
    visibility: hidden;
    border: 2px solid #E5E5E5;
    padding: 1.25vw;
    position: absolute;
    z-index: 2;
    margin: auto;
    bottom: 120%;
    right: -75%;
    color: black;
    width: 250%;
    /* Fade in tooltip - takes 1 second to go from 0% to 100% opac: */
    opacity: 0;
    transition: opacity 1s;
    font-size: 1vw;
    line-height: 1.5vw;
}
.sticky .tooltiptext::after{
    content: " ";
    position: absolute;
    top: 100%;
    left: 50%;
    right: 50%;
    margin-left: -10px;
    border-width: 10px;
    border-style: solid;
    border-color: white transparent transparent transparent;
}
.sticky .iconsRow > div:hover > .tooltiptext,
.sticky .iconsRow > div:focus > .tooltiptext {
    visibility: visible;
    opacity: 1;
}
.sticky .iconsRow > div > div:last-child {
    font-size: 0.94vw;
    line-height: 1.04vw;
    font-family: 'ProximaNova-Regular'/*rtl:"NarkisBlockMFW-Regular"*/;
    width: 100%;
}
.sticky .iconsRow div[class$="Icon"] {
    height: 40px;
    width: 40px;
    background-repeat: no-repeat;
}
.clubSticky {
    min-height: 22.2vw;
    padding: 25px 0;
    width: 100%;
    padding: 1vw;
    /*line-height: 1.5vw;*/
    background-color: #2C4F7C;
    transition: opacity 500ms;
    position: absolute;
}
.clubSticky.hidden {
    display: inherit;
    opacity: 0;
}
.clubSticky.show{
    opacity: 1;
}
.clubSticky > div > div.title {
   color: #bce1f3;
    font-family: 'ProximaNova-Semibold'/*rtl:"NarkisBlockMFW-Regular"*/;
    font-size: 1.25vw;
    font-weight: 600;
    text-align: center;
    margin: auto; 
    line-height: 23px;
}
.clubSticky > div > div.edanBtns {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-top: 1.05vw;
    margin-bottom: 1.42vw;
}
.clubSticky > div > div.edanBtns > a {
    border: none;
    background-color: #bce1f3;
    color: rgba(255, 255, 255, 0.79);
    font-size: 0.945vw;
    text-align: center;
    padding: 0.88vw;
    color: #2c4f7c;
    font-family: 'ProximaNova-Semibold' !important/*rtl:"NarkisBlockMFW-Regular"; */;
    cursor: pointer;
}
.clubSticky > div > div.edanBtns > a:first-child {
    margin-right: 1px;
}
.clubSticky .iconsRow {
    display: flex;
    justify-content: space-between;
    margin: 0.705vw;
    margin-left: 0px;
    margin-right: 0px;
}
.clubSticky .iconsRow > div {
    width: 33%;
    text-align: center;  
    display: flex;
    flex-direction: column;
    align-items: center; 
    color: white;
    position: relative;
}
.clubSticky .iconsRow .bgImg {
    background-color: white;
    display: flex;
    height: 3.28vw;
    width: 3.25vw;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
}
.clubSticky .iconsRow .bgImg img{
    width: 68%;
}  
.clubSticky .iconsRow .txt{
    margin-top: 9px;
    width: 90%;
    text-align: center;
    font-size: 0.87vw;
    line-height: 1vw;
} 
.clubSticky .tooltiptext {
    visibility: hidden;
    border: 2px solid #E5E5E5;
    padding: 1.25vw;
    position: absolute;
    z-index: 2;
    margin: auto;
    bottom: 120%;
    right: -75%;
    color: black;
    width: 250%;
    /* Fade in tooltip - takes 1 second to go from 0% to 100% opac: */
    opacity: 0;
    transition: opacity 1s;
    font-size: 1vw;
    line-height: 1.5vw;
    
}
.clubSticky .tooltiptext::after {
    content: " ";
    position: absolute;
    top: 99%;
    left: 50%;
    right: 50%;
    margin-left: -10px;
    border-width: 10px;
    border-style: solid;
    border-color: white transparent transparent transparent;
}
.clubSticky .tooltiptext:after, .tooltiptext:before {
    top: 100%;
    left: 50%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
}
.clubSticky .tooltiptext:before {
    border-top-color: #e5e5e5;
    border-width: 1.2vw;
    margin-left: -1.2vw;
}
.clubSticky > div > .iconsRow > div:hover > .tooltiptext, .clubSticky > div > .iconsRow > div:focus > .tooltiptext {
    visibility: visible;
    opacity: 1;
}

/*------Global css to all tooltips------*/
.tooltiptext {
    visibility: hidden;
    /*max-width: 200%;*/
    background-color: #fff;
    text-align: center;
    border: 2px solid #E5E5E5;
    padding: 16px;
    position: absolute;
    z-index: 2;
    margin: auto;
    bottom: 120%;
    right: 0%;
    cursor: pointer;
    opacity: 0;
    transition: opacity 1s;
}
.tooltiptext p {
    margin: 0;
}
.tooltipLink:hover, .tooltipLink:focus {
    position: relative;
}
.tooltipLink:hover ~ .tooltiptext, .tooltipLink:hover > .tooltiptext,
 .tooltipLink:focus ~ .tooltiptext, .tooltipLink:focus > .tooltiptext,
 .tooltipLink.active ~ .tooltiptext, .tooltipLink.active > .tooltiptext {
    visibility: visible;
    opacity: 1;
}
.tooltiptext:hover, .tooltiptext:focus {
    visibility: visible;
    opacity: 1;
}
/*--------------------------*/
@media screen and (max-width: 767px) {
    .sticky.block {
        display: none;
    }
}