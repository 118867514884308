.select-box-links{
    text-align: left;
    z-index: 2;
}
.select-box-links .selectBox {
    border-width: 0.09375vw;
    border-style: solid;
    height: 3.4375vw;
    border-color: black;
}
.select-box-links.blue .selectBox {
    border-color:  #20507b;
    color: #20507b !important;
    border-width: 2px;
    line-height: 3vw;
}
.select-box-links .selectBox fieldset {
    border: none;
}
.select-box-links.blue .selectBox .selectInput {
    color: #20507b;
    line-height: 3.4vw;
    height: 3vw;
}
.select-box-links.blue .MuiSelect-icon {
    color: #20507b;
}
.MuiMenu-list li.blue-option {
    color: #20507b ;
    padding-inline-start: 0.8rem;
    font-size: 1.375rem;
}

@media screen and (max-width: 767px) {
    .select-box-links.mobile-medium {
        font-size: 3vw;
        line-height: normal;
    }
    .select-box-links.mobile-medium .selectBox {
        height: 6vw;
    }
    .select-box-links.mobile-medium  .MuiSelect-select {
        padding-block: 0.5em;
    }
    .select-box-links .blue-option{
        padding-left: 3.5vw;
    }
    .MuiMenu-list li.blue-option {
        font-size: 5vw;
    }
    .select-box-links.blue .selectBox {
        height: 13.09375vw;
    }
    .select-box-links.blue .selectBox .selectInput {
        height:100%; 
        line-height: 7vw;
    }
}