.selectBox {
    font-family: inherit;
}

.selectBox label:not(.MuiInputLabel-shrink) {
    margin: 0;
    line-height: 1;
    height: 100%;
    margin-top: -0.3em;
    inset-inline-start: 0;
    inset-inline-end: unset;
    font-size: inherit;
}

.selectBox label:lang(he) {
    inset-inline-start: 1.2em;
}

.selectBox .selectInput {
    color: #333;
    border-radius: unset;
    font-size: inherit;
}
.selectBox .MuiSelect-select {
    font-family: 'ProximaNova-Regular';
    letter-spacing: normal;
}

@media screen and (max-width: 767px) {
    .selectBox .selectInput {
        height: 100%;
    }
}

.selectBox .MuiSelect-icon {
    inset-inline-end: 0.3em;
    inset-inline-start: unset;
}

.selectBox .MuiFormHelperText-contained.Mui-error {
    text-align: start;
    color: #d31f12;
    margin: 0;
}


.MuiMenu-list li {
    font-size: 1rem;
    line-height: normal;
    font-family: inherit;
}

@media screen and (max-width: 767px) {
    .MuiMenu-list li {
        font-size: 1.55rem;
    }
}

.MuiMenu-list li.Mui-selected {
    background-color: rgba(0, 126, 255, 0.04) !important;
}

.MuiMenu-list li:hover {
    background-color: rgba(0, 126, 255, 0.04) !important;
}

.MuiMenu-list li:first-child.Mui-disabled.no-options {
    display: block;
}