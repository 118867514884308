.packagesGroup {
    width: 91.25%;
    margin: auto;
    display: flex;
    justify-content: space-between;
    direction: ltr;
}
.packagesGroup a {
    color: black;
}
.packagesGroup.deals a {
    color: white;
}
.packagesGroup:last-child {
    margin-bottom: 3.62vw;
}
.areaPackages .packagesGroup {
    background-color: #3B4860;
    background-repeat: no-repeat;
    background-size: 100%;
    color: #fff;
    margin-top: 3.62vw;
    min-height: 53.75vw;
}
.areaPackages .packagesGroup .content, .areaCategoriesPackages .packagesGroup .content {
    display: flex;
    justify-content: space-around;
    width: 91.1875vw;
}
.packagesGroup .hotelDetails {
    width: 23.13vw;
    padding-top: 1.88vw;/*2.38vw;*/
    /*margin-left: 5vw;*/
}
.packagesGroup .hotelName {
    margin: 0;
    font-weight: inherit;
    font-family: 'ProximaNova-bold' /*rtl:"NarkisBlockMFW-bold"*/;
    line-height: 1em;
    text-transform: capitalize;
    margin-top: 1.56vw;
    margin-bottom: 1.56vw;
    font-size: 2.99vw;
}
.packagesGroup .subTitle {
    font-size: 1.87vw;
    font-family: 'ProximaNova-semibold' /*rtl:"NarkisBlockMFW-regular"*/;
    text-transform: capitalize;
}
.packagesGroup .hotelDetails .description {
    font-size: 1.25vw;
    line-height: 1.43vw;
    margin-top: 2.19vw;
}
.packagesGroup .links {
    display: flex;
    justify-content: space-between;
    margin-top: 2.5vw;
}
.packagesGroup .links a {
    color: #fff;
    text-decoration: none;
    text-transform: uppercase;
    font-size: 1vw;
    border-bottom: 0.06vw solid;
    line-height: 1.43vw;
    font-family: 'ProximaNova-bold' /*rtl:"NarkisBlockMFW-bold"*/;
}
.packagesGroup .deals {
    margin-top: 0.81vw;
    /*margin-right: 3.75vw;*/
    width: 47.13vw;
    display: flex;
    justify-content: space-between;
    align-content: flex-start;
    flex-wrap: wrap;
    margin-bottom: 3.44vw;
}
/*.packagesGroup .deals .dealsList > div {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-content: flex-start;
    flex-wrap: wrap;
}
.packagesGroup .deals .slideBtn {
    display: none;
}*/
.packagesGroup .deal {
    width: 22.56vw;
    min-height: 22.5vw;
    color: #20507b;  
    margin-top: 2.13vw;
    position: relative;
    perspective: 800px; /*??????????*/
    z-index: 1;
}
.packagesGroup .deal .flipDeal {
    width: 100%;
    min-height: 22.5vw;
    /*height: 100%;*/
    /*position: absolute;*/
    transform-style: preserve-3d;
    transition: transform 1s;
    background-color: #fff;
    display: block;
}
.packagesGroup .deal .flipDeal.flipped {
    transform: rotateY( 180.0001deg );
    -ms-transform: rotateY( 360deg ); /* IE 9 */
    -webkit-transform: rotateY( 180.0001deg );
}
.packagesGroup .deal .aboutDeal, .packagesGroup .deal .moreDetails {
    display: block;
    /*position: absolute;*/
    width: 100%;
    /*height: 100%;*/
    backface-visibility: hidden;
}
.packagesGroup .deal .aboutDeal {
    padding: 1.94vw 1.44vw 1.5vw;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 22.5vw;
}
.packagesGroup .deal .aboutDeal .details {
    max-height: 16vw;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.packagesGroup .deal .aboutDeal .actions {
    display: flex;
    margin-bottom: 0;
   /* position: absolute;
    bottom: 1.5vw;
    width: 19.5vw;*/
}
.packagesGroup .deal .moreDetails {
    transform: rotateY( 180deg );
    -ms-transform: rotateY( 360deg ); /* IE 9 */
    -webkit-transform: rotateY( 180deg );
    padding: 1.94vw 1.44vw 1.63vw;
    display: flex;
    flex-direction: column;
    min-height: 22.5vw;
    justify-content: space-between;
}
.packagesGroup .deal .name  {
    font-size: 2.63vw;
    /*line-height: 1.99vw;*/
    line-height: 2.63vw;
    margin-bottom: 0.2vw;
}
.packagesGroup .deal .date {
    font-size: 1.25vw;
    line-height: 2.61vw;
    font-weight: bold;
    /*margin-top: 1.63vw;/*check it!!*/
}
.packagesGroup .deal .bookBtn {
    width: 9.75vw;
    height: 3.13vw;
    background-color: #f07066;
    color: #fff;
    font-size: 1.25vw;
    text-transform: uppercase;
    line-height: 2.28vw;
    text-align: center;
    /* margin-top: 2.69vw; */
    padding: 0;
    border-radius: 0;
}
.packagesGroup .deal .flipped .description {
    font-size: 1.13vw;
    line-height: 1.63vw;
    min-height: 11.15vw;
}
.packagesGroup .deal .flipped .description ul, .packagesGroup .hotelDetails .description ul {
    list-style-type: disc;
    margin-left: 1vw;
}
.packagesGroup .deal a.showMoreDetails {
    text-transform: uppercase;
    font-size: 1vw;
    margin: auto auto 0;
    text-decoration: none;
    color: #20507b;
    font-family: 'ProximaNova-bold' /*rtl:"NarkisBlockMFW-bold"*/;
    display: block;
    cursor: pointer;
    /*position: absolute;
    bottom: -0.2vw;
    left: 10.5vw;*/
}
.packagesGroup .deal a.about {
    margin-top: 1.44vw;
    font-size: 1.13vw;
    text-transform: capitalize;
}
.packagesGroup .deal .flipped .moreDetails .bookBtn {
    /*margin-top: 1.44vw;*/
    display: block;
    /*position: absolute;
    bottom: 1.5vw;*/
}
.packagesGroup .deal .dates.move .DateRangePicker__picker {
    left: inherit !important;
    right: 0;
}
.regular .moreDetails {
    max-height: 0;
}
.packagesGroup .deal .flipped .aboutDeal {
    max-height: 0;
}
.packagesGroup .deal .moreDetails .closeBtn, .deal .bookDetails .closeBtn {
    background: url("../../images/close.png") no-repeat;
    width: 0.88vw;
    height: 0.88vw;
    position: absolute;
    top: 0.69vw;
    right: 0.81vw;
    cursor: pointer;
    background-size: 0.88vw 0.88vw;
}
.areaCategoriesPackages .subTitle {
    display: none;
}
.areaCategoriesPackages .packagesGroup {
    background-color:  #bbe1f2;
    background-repeat: no-repeat;
    background-position: bottom left;
    color:  #20507b;
    min-height: 53.75vw;
}
.areaCategoriesPackages .packagesGroup .links a {
    color:  #20507b;
}
.deal .bookDetails {
    display: flex;
    flex-direction: column;
    width: 100%;
}
.deal .bookDetails .dates {
    width: 100%;
    padding-bottom: 0.7vw;
}
.deal .dates .DateRangePicker > div:first-child {
    width: 100%;
}
.deal .dates .DateInput {
    background-color: #F1F1F1;
    width: 49%;
}
.deal .searchBtn {
    cursor: pointer;
    background-color:  #f07066;
    color: #fff;
    font-size: 1.25vw;
    text-transform: uppercase;
    text-align: center;
}
.deal .selectBox {
    background-color: #F1F1F1;
    font-size: inherit;
}
.deal .selectBox .MuiSelect-select.MuiInputBase-input {
    padding: .445rem;
    font-weight: bold;
}
.deal .MuiOutlinedInput-notchedOutline {
    border: none;
}
.deal .people {
    padding-bottom: 0.7vw;
}
.deal .people .options {
    width: 100%;
    font-size: 0.8vw;
}
.deal .people .moreOptions {
    padding-bottom: 0;
    display: flex;
    flex-direction: column;
    border-bottom: none;
}


.deal .Select-control {
    height: 2.2vw;
}
.deal .Select-placeholder {
    line-height: 2vw;
}
.deal .Select-value {
    line-height: 2.5vw !important;
}
.deal .Select-value-label {
    line-height: 2vw;
    font-weight: bold;
}
.deal .Select-input {
    height: 2vw;
}
.deal .Select-option {
    padding: 0.1vw 10px;
}

.deal .people .room {
    display: block;
    width: 100%;
}
.deal .people .room:first-child {
    width: 100%;
}
.deal .people .room > div {
    float: left;
    width: 31.5%;
}
.deal .people .room > div:first-child {
    width: 100%;
    font-weight: bold;
}
.deal .people .number {
    width: 90%;
}
.deal .people .room > div:last-child {
    width: 5%;
}
.packagesGroup .noDeals {
    text-align: center;
    font-size: 2.1vw;
    font-weight: 400;
    /*color: #20507b;*/
    width: 100%;
    margin-top: 3.5vw;
}

.hotelPackages {
    width: 71.06vw;
}
.hotelPackages .deals {
    width: 100%;
    margin-right: 0;
    margin-top: 1.5vw;
}
.hotelPackages .deal {
    margin-top: 1.78vw;
    text-align: left;
    direction: ltr;
}
.hotelPackages .placeHolder {
    width: 22.56vw;
}
.packagesGroup .deals.hotelPackagesCarousel {
    display: flex;
    justify-content: space-around;
    flex-direction: row /*rtl:row-reverse*/;
}
.hotelPackages .slider-decorator-0 button, .hotelPackages .slider-decorator-1 button {
    display: none;
}
.hotelPackages .slider-list {
    height: 55vw !important;
}
.large .slider-list {
    min-height: 85vw;
}
.slider-frame {
    /*rtl:begin:ignore*/
    direction: ltr;
    /*rtl:end:ignore*/
}

/*area page---*/

.areaPackages .packagesGroup.area {
    background-color: #ffffff;
    color:  #000000;
    box-shadow: 0px 0.1875vw 0.4375vw 0px rgba(0, 0, 0, 0.18);
    display: flex;
    flex-direction: column;
}
.areaPackages .packagesGroup.area > div:first-child {
    /*width: 91.1875vw;*/
    height: 29.126vw;
    background-repeat: no-repeat;
    background-size: 100%;
}
.areaPackages .packagesGroup.area .content {
    min-height: 28.56vw;
}
.packagesGroup.area .deal .flipDeal {
    border: 0.0625vw solid #97743c;
}
.packagesGroup.area .deal, .packagesGroup.area .noDeals, .packagesGroup.area .deal a.showMoreDetails {
    color:  #020202;
}
.packagesGroup.area .links a {
    color:  #000000;
}
/*---area page*/

@media all and (-ms-high-contrast:none) {
    .packagesGroup .deal .aboutDeal .actions.close {
        position: absolute;
        bottom: 1.5vw;
        width: 19.5vw;
    }
    .packagesGroup .deal a.showMoreDetails {
        position: absolute;
        bottom: -0.2vw;
        left: 10.5vw;
    }
    .packagesGroup .deal .flipped .description {
        height: 11.15vw;
    }
    .packagesGroup .deal .flipped .moreDetails .bookBtn {
        position: absolute;
        bottom: 1.5vw;
    }
}

@media screen and (max-width: 767px) {
    .packagesGroup {
        width: 84.53vw;
        display: block;
        margin-top: 7.5vw;
    }
    .areaPackages .packagesGroup {
        background-position: bottom;
        background-color: #3B4860;
    }
    .areaPackages .packagesGroup .content, .areaCategoriesPackages .packagesGroup .content {
        display: block;
        width: 84.53vw;
        position: relative;
    }
    .packagesGroup .noDeals {
        font-size: 6vw;
        width: 70vw;
        margin-top: 3.5vw;
        margin: auto;
        position: relative;
    }
    .packagesGroup .hotelDetails {
        width: auto;
        padding-top: 7.66vw;
        margin: 0 7.5vw;
        position: relative;
    }
    .content .gradient {
        position: absolute;
        height: 100%;
        width: 100%;
        background-image: linear-gradient(#3B4860 58%, transparent);
    }
    .packagesGroup .hotelName {
        font-size: 7.49vw;
        line-height: 6.61vw;
    }
    .packagesGroup .subTitle {
        font-size: 5.61vw;
        line-height: 7.81vw;
        transform: scaleY(1.0018);
        -ms-transform: scaleY(1.0018); /* IE 9 */
        -webkit-transform: scaleY(1.0018);
    }
    .packagesGroup .hotelDetails .description {
        font-size: 4.37vw;
        line-height: 4.41vw;
        transform: scaleY(1.0018);
        -ms-transform: scaleY(1.0018); /* IE 9 */
        -webkit-transform: scaleY(1.0018);
        margin-top: 5.31vw;
    }
    .packagesGroup .links {
        display: block;
        margin-top: 0.63vw;
    }
    .packagesGroup .links a {
        font-size: 4.37vw;
        line-height: 3.58vw;
        display: table;
        padding-bottom: 1.56vw;
        border-width: 0.16vw;
        margin-top: 8.44vw;
    }
    .packagesGroup .deals {
        margin-top: 10vw;
        width: auto;
        padding-bottom: 15.31vw;
        margin-right: 0;
        /*display: flex;*/
    }
    .packagesGroup .deals .slider-decorator-1, .packagesGroup .deals .slider-decorator-0 {
    /*.slider-decorator-1, .slider-decorator-0 {*/
        top: calc(20vw + 37%) !important;
    }
    .packagesGroup .deals.large .slider-decorator-1, .packagesGroup .deals.large .slider-decorator-0 {
        top: calc(20vw + 25%) !important;
    }
    .packagesGroup .deals .slider-decorator-0 button, .packagesGroup .deals .slider-decorator-1 button {
        background: none !important;
        height: 4.34vw;
        width: 4.34vw;
        /*rtl:begin:ignore*/
        transform: rotate(-45deg);
        -ms-transform: rotate(-45deg); /* IE 9 */
        -webkit-transform: rotate(-45deg);
        /*rtl:end:ignore*/
        padding: 0 !important;
        border-radius: 0;
        font-size: 0;
    }
    .packagesGroup .deals .slider-decorator-0 button {
        /*rtl:begin:ignore*/
        border-left: 0.47vw solid !important;
        border-top: 0.47vw solid !important; 
        margin-left: 2.97vw;
        /*rtl:end:ignore*/
    }
    .packagesGroup .deals .slider-decorator-1 button {
        /*rtl:begin:ignore*/
        border-right: 0.47vw solid !important;
        border-bottom: 0.47vw solid !important;
        margin-right: 2.97vw;
        /*rtl:end:ignore*/
    }
    .packagesGroup .deals .slider-decorator-2 {
        display: none;
    }
    /*.packagesGroup .deals .slideBtn {
        height: 100%;
        margin: auto;
        display: block;
    }
    .packagesGroup .deals .slideBtn button { 
        background: none;
        height: 2.34vw;
        width: 2.34vw;
        transform: rotate(-45deg);
        padding: 0;
        border-radius: 0;
    }
    .packagesGroup .deals .slideBtn .slideLeft {
        border-left: 0.47vw solid;
        border-top: 0.47vw solid; 
    }
    .packagesGroup .deals .slideBtn .slideRight {
        border-right: 0.47vw solid;
        border-bottom: 0.47vw solid;
    }
    .packagesGroup .deals .dealsList {
        overflow: hidden;
        width: 69.06vw;
    }
    .packagesGroup .deals .dealsList > div  {
        display: flex;
        justify-content: space-between;
        -webkit-transition: all 1.0s ease-in-out;
        -moz-transition: all 1.0s ease-in-out;
        -o-transition: all 1.0s ease-in-out;
        transition: all 1.0s ease-in-out;
    }*/
    .packagesGroup .deal {
        margin: auto;
        margin-top: 0;
        width: 69.06vw;
        min-height: 69.06vw;
        height: inherit;
        margin-top: 20vw;
        direction: ltr !important;
        text-align: left;
        /*margin-left: 14vw;*/
    }
    .packagesGroup .deal .aboutDeal {
        padding: 6.25vw 7.66vw 7.5vw;
        min-height: 69.09vw;
    }
    .packagesGroup .deal .aboutDeal .details {
        max-height: 43vw;
    }
/*    .packagesGroup .deal .aboutDeal .actions {
        bottom: 7.5vw;
        width: inherit;
    }*/
    .packagesGroup .deal .moreDetails {
        padding: 6.25vw 7.66vw 7.5vw;
        min-height: 69.09vw;
    }
    .packagesGroup .deal .name {
        font-size: 7.19vw;
        line-height: 7.19vw;
        max-height: 40vw;
    }
    .packagesGroup .deal .date {
        font-size: 4.38vw;
        line-height: 6.88vw;
        margin-bottom: 2.69vw;
    }
    .deal .dates .DateRangePicker table {
        transform: translateX(0px);
    }
    .deals .CalendarDay__button {
        font-size: 3vw;
    }
    .packagesGroup .deal .bookBtn {
        width: 30vw;
        height: 11.41vw;
        font-size: 4.5vw;
        line-height: 5.69vw;
    }
    .packagesGroup .deal .flipped .description {
        font-size: 2.81vw;
        line-height: 4.06vw;
        min-height: 30.47vw;
    }
    .packagesGroup .deal .flipped .description ul, .packagesGroup .hotelDetails .description ul {
        margin-left: 2.5vw;
    }    
    .packagesGroup .deal a.showMoreDetails {
        font-size: 4.06vw;
        width: 16vw;
        /*left: 35vw;*/
    }
    .packagesGroup .deal .actions.auto a.showMoreDetails {
        width: auto;
    }
    .packagesGroup .deal a.about {
        margin-top: 3.59vw;
        font-size: 2.81vw;
    }
    .packagesGroup .deal .flipped .moreDetails .bookBtn {
        margin-top: 3.59vw;
        bottom: 7.5vw;
    }
    .packagesGroup .deal .flipped .moreDetails .closeBtn, .deal .bookDetails .closeBtn {
        width: 2.19vw;
        height: 2.19vw;
        top: 1.72vw;
        right: 2.03vw;
        background-size: 2.19vw 2.19vw;
    }

    .deal .bookDetails {
        width: 55vw;
    }
    .deal .Select-control {
        height: 7.2vw;
    }
    .deal .Select-placeholder {
        line-height: 6.5vw;
    }
    .deal .Select-value {
        line-height: 8vw !important;
    }
    .deal .Select-value-label {
        line-height: 6.5vw;
    }
    .deal .Select-input {
        height: 6.5vw;
    }
    .deal .Select-option {
        padding: 0.1vw 10px;
    }

    .deal .people .options {
        font-size: 3vw;
    }
    .deal .people {
        padding-bottom: 5vw;
    }
    .deal .people .room > div:last-child {
        line-height: 0;
        padding-top: 7.5vw;
    }
    .deal .searchBtn {
        font-size: 4vw;
    }
    .packagesGroup .deal .flipDeal {
        min-height: 69.09vw;
    }

    ul.slider-list {
        min-height: 90vw;
        height: inherit !important;
    }
    .large ul.slider-list {
        min-height: 150vw;
    }
    li.slider-slide {
        height: 100% !important;
    }
    .deal .DateRangePicker__picker {
        left: -7.66vw !important;
    }
    .hotelPackages .deals{
        margin-top: 0;
    }
    .hotelPackages .deals.onePackage {
        margin-left: auto;
        margin-right: auto;
    }
    .hotelPackages.packagesGroup {
        margin-top:0;
        position: relative;
    }
    .hotelPackages.packagesGroup .gradient {
        position: absolute;
        height: 98%;
        width: 100%;
        background-image: linear-gradient(#3B4860 60%, transparent);
        top: 2%;
    }
    .packagesGroup.area .gradient, .areaCategoriesPackages .gradient {
        display: none;
    }

    /*area page---*/

    .areaPackages .packagesGroup.area, .packagesGroup.area .links a {
        color:  #212e3a;
    }
    .areaPackages .packagesGroup.area > div:first-child {
        height: 27.345vw;
    }
    /*.areaPackages .packagesGroup.area .content {
        min-height: 28.56vw;
    }
    .packagesGroup.area .deal .flipDeal {
        border: 0.0625vw solid #97743c;
    }*/
    .packagesGroup.area .deal, .packagesGroup.area .noDeals, .packagesGroup.area .deal a.showMoreDetails {
        color: #20507b;
    }
    .packagesGroup.area .deals .slider-decorator-0 button, .packagesGroup.area .deals .slider-decorator-1 button {
        color: #20507b !important;
    }
    /*.packagesGroup.area .links a {
        color:  #000000;
    }*/
    /*---area page*/
}