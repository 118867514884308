body.pojo-a11y-resize-font-130 .Select-placeholder {
    font-size: 130% !important;
}
body.pojo-a11y-resize-font-130 .DateRangePickerInput .DateInput__display-text {
    font-size: 130% !important;
}
.dates table {
    margin: auto;
    table-layout: auto;
    width: auto;
}
.editReservationContainer {
    display: flex;
    justify-content: space-between;
    background-color: #222E3A;
    color: white;
    padding: 3.25vw 6%;
    max-height: 100vw;
    align-items: flex-start;
    height: inherit;
}
.editReservationContainer .edanClubForm {
    position: initial;
}
.editReservationContainer.open {
    transition-property: all;
    transition-duration: .50s;
    transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
}
.editReservationContainer.closed {
    max-height: 0;
    /*height: 0;*/
    padding: 0 6%;
}
.editReservationContainer > div:first-child {
    width: 6.5vw;
}
.editReservationHeader {
    font-family: 'ProximaNova-Bold'/*rtl:"NarkisBlockMFW-Bold"*/;
    font-size: 1.5vw;
    line-height: 2vw;
}
.editReservationDetails {
    z-index: 998;
    width: 50.1%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.editReservationDetails > div {
    margin-bottom: 2vw;
}
.dates {
    float: left;
}
.dates > div:first-child, .dates > div.errors-block {
    display: flex;
    justify-content: flex-start;
}
.dates > div:first-child > span:first-child, .dates > div.errors-block > span:first-child {
    width: 54.8%;
}
.dates .DayPickerNavigation__prev, .dates .DayPickerNavigation__next {
    background-color: #EF6F66;
    border-radius: 50%;
    height: 2vw;
    width: 2vw;
}
.dates .arrow {
    border: white solid;
    border-width: 0 0.1vw 0.1vw 0;
    display: inline-block;
    padding: 0.2vw;
}
.dates .right {
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
}

.dates .left {
    transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
}

.dates .up {
    transform: rotate(-135deg);
    -webkit-transform: rotate(-135deg);
}

.dates .down {
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
}
.DateRangePicker {
    width: 100%;
}
.DateRangePickerInput {
    border: none;
    background-color: transparent;
    display: flex;
    justify-content: space-between;
}
.DayPickerKeyboardShortcuts__show {
    display: none;
}
.CalendarMonth td {
    border: none;
}
body.pojo-a11y-resize-font-130 .CalendarMonth td, body.pojo-a11y-resize-font-130 .DayPicker__week-header li small, body.pojo-a11y-resize-font-130 .CalendarMonth__caption strong {
    font-size: 130% !important;
}
.DayPickerKeyboardShortcuts__panel {
    color: black;
}
.DayPicker__week-header li small {
    display: block;
    color: white;
    font-size: 1.2vw;
    font-weight: bold;
    margin-left: 1vw/*rtl:0*/;
}
.DayPicker__week-header li small:first-letter {
    color: black;
}
.transition-container.transition-container--horizontal:after {
    content: "";
    position: absolute;
    top: 3vw;
    bottom: 2vw;
    left: 50%;
    border-left: 1px solid #D1D1D1;
    transform: translate(-50%);
}
.DateRangePicker__picker {
    left: 0;
}
.editReservationContainer.open.mini .DateInput {
    border: none;
}
.editReservationContainer.open .DateInput {
    border: 1px solid #ccc;
}
.DateInput {
    width: 45%;
    padding: 0;
}
.DateInput--with-caret::before, .DateInput--with-caret::after {
    display: none;
}
.calendarInfo {
    border-top: 1.5px solid black;
    color: #74757A;
    width: 92%;
    margin: auto;
    padding-bottom: 1vw;
}
.calendarInfo > div {
    margin-top: 0.4vw;
}
.calendarInfo .title {
    background-color: #DEDEDE;
    text-align: left;
}
.calendarInfo .menu {
    display: flex;
    justify-content: flex-start;
}
.calendarInfo .menu > span {
    display: flex;
    width: 30%;
    font-size: 0.8vw;
    align-items: center;
}
.calendarInfo .menu > span:last-child {
    flex-direction: row-reverse;
    cursor: pointer;
}
.calendarInfo .menu .square {
    height: 1vw;
    width: 1vw;
    margin-right: 0.5vw;
}
.calendarInfo .menu .available {
    border: 1px solid black;
}
.calendarInfo .menu .noAvailability {
    background-color: #CCCCCC;
}
.calendarInfo .menu .restrictions {
    background-color: #F07065;
}
.editReservationDetails .Select-control {
    border-radius: 0;
}
.updateRates {
    background-color: #F07066;
    line-height: 4vw;
    font-size: 2vw;
    text-align: center;
    cursor: pointer;
    border: 1px solid #F07066;
}
.people .options {
    width: 40%;
}
.people .moreOptions {
    border-bottom: 1.2px solid #E6E7E9;
    padding-bottom: 1.5vw;
}
.people .moreOptions .addAnother {
    color: #89C6E4;
    cursor: pointer;
}
.people .number {
    width: 50%;
    padding-top: 1.5em;
}
.people .room {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: flex-end;
    margin-top: 1vw;
}
.people .room label {
    color: inherit;
    inset-inline-start: 0;
    margin: -.8em 0 0;
    font-family: inherit;
    transform: unset;
    top: -.5rem;
    max-width: unset;
}
.people .room.last {
    padding-bottom: 1.5vw;
}
.people .room > div {
    width: 45%;
}
.people .room > div:first-child {
    width: 30%;
}
.people .room > div:last-child {
    width: 5%;
}
.people .room:first-child {
    width: 97%;
}
.people .room .clear {
    cursor: pointer;
}
.people .room:first-child .clear {
    display: none;
}
.editReservationContainer .clubSticky {
    /* height: 30vw; */
    width: 26vw;
    background-color: #fff;
    position: relative;
    color: #333;
    padding: 1.5vw 3vw;
}
.editReservationContainer .clubSticky .iconsRow {
    margin: 1vw 0 1.5vw;
}
.editReservationContainer .clubSticky .iconsRow .bgImg {
    height: 4.92vw;
    width: 4.92vw;
    background-color: #C7E6F6;
}
.editReservationContainer .clubSticky .iconsRow .txt {
    color: #333;
}
.editReservationContainer .clubSticky > div > div.title {
    color: #2C4F7C;
}
.editReservationContainer .clubSticky > div > div.edanBtns > a {
    background-color: #2C4F7C;
    color: #fff;
    border: 1px solid #2C4F7C;
}
.editReservationContainer .clubSticky .secondaryTitle {
    margin-top: 0.5vw;
    font-size: 1vw;
}
.editReservationContainer .clubSticky .secondaryTitle .helloMember {
    color: #B6D8EA;
    font-size: 1.25vw;
    font-weight: bold;
    text-align: center;
}
.editReservationContainer .clubSticky .secondaryTitle .statusMember {
    display: flex;
    margin-top: 0.5vw;
}
.editReservationContainer .clubSticky .secondaryTitle .statusMember > div {
    display: flex;
    flex-direction: column;
    border: 1px solid #dedede;
    border-right: none;
    padding: 0.3vw 0.5vw;
}
.editReservationContainer .clubSticky .secondaryTitle .statusMember > div:nth-child(2) {
    width: 30%
}
.editReservationContainer .clubSticky .secondaryTitle .statusMember > div:not(:nth-child(2)) {
    width: 35%
}
.editReservationContainer .clubSticky .secondaryTitle .statusMember > div:last-child {
    border-right: 1px solid #dedede;
}
.editReservationContainer .clubSticky .secondaryTitle .statusMember > div > span {
    line-height: 1vw;
    font-size: 0.87vw;
}
.editReservationContainer .clubSticky .secondaryTitle .statusMember > div > span:last-child {
    font-weight: bold;
    margin-top: 0.2vw;
}
.editReservationContainer .selectBox {
    background-color: white;
}

.editReservationContainer .MuiSelect-select {
    padding-top: 0.5em;
    padding-bottom: 0.3em;
    font-size: inherit;
    font-weight: bold;
    color: #353b49;
}

.editReservationContainer .selectBox .Mui-focused .MuiOutlinedInput-notchedOutline {
    border: 1px solid #56b1e1;
}


.dates .CalendarMonth button:focus {
    border: 1px solid #56b1e1;
    background-color: white;
    color: inherit;
}
.dates.error .DateInput__display-text, .people.error .Select, .hotels.error .Select, .dates.endDateErr .DateInput:last-child .DateInput__display-text, .dates.startDateErr .DateInput:first-child .DateInput__display-text {
    border: 2px solid #D9584F;
}
.editReservationContainer .errors {
    color: #D9584F;
    height: 0;
}
.editReservationContainer .errors.checkOut {
    left: 41.8vw;
    position: absolute;
}


.editReservationContainer.asMobile {
    flex-direction: column;
}
.editReservationContainer.asMobile > div:first-child {
    width: auto;
}
.editReservationContainer.asMobile .editReservationDetails {
    width: 18.1885vw;
}
.editReservationContainer.mini.asMobile .editReservationDetails {
    width: auto;
}
.editReservationContainer.asMobile .Select-value, .editReservationContainer.asMobile .Select-placeholder {
    line-height: 2.75vw;
}
.editReservationContainer.asMobile .people .options {
    width: auto;
}
.editReservationContainer.asMobile .edanClub {
    display: none;
}
.editReservationContainer.asMobile .DateRangePickerInput {
    height: 2.8757vw;
}
.editReservationContainer.asMobile .updateRates {
    font-size: 1.25vw;
    line-height: 3.125vw;
}
.editReservationContainer.asMobile.mini .editReservationDetails > div:first-child {
    flex-direction: column;
}
.editReservationContainer.asMobile .dates.move .DateRangePicker__picker {
    left: inherit !important;
    right: 0;
}
.editReservationContainer.asMobile .Select-value-label {
    line-height: 2.75vw;
}
.editReservationContainer.asMobile .Select-menu-outer {
    line-height: 1vw !important;
}
.editReservationContainer.asMobile .hotels .group {
    height: 1vw;
}

/*-------------------mini-------------------*/
.editReservationContainer.mini {
    justify-content: space-between;
    padding: 1.7vw 16.5% 1.7vw 19%;
    background-color: rgba(34, 46, 58, 0.97);
    opacity: 0.98;
}
.editReservationContainer.mini .editReservationHeader {
    font-size: 1.5vw;
    line-height: 1.5vw;
}
.editReservationContainer.mini .editReservationHeader .arrow {
    display: none;
}
.editReservationContainer.mini.fullMode {
    min-height: 33vw;
}
.editReservationContainer.mini .editReservationDetails {
    width: 51.38vw;
}
.editReservationContainer.mini .editReservationDetails > div {
    margin-bottom: 0.5vw;
}
.editReservationContainer.mini .editReservationDetails > div:first-child {
    display: flex;
    justify-content: space-between;
}
.editReservationContainer.mini .editReservationDetails .hotels {
    width: 24.178vw;
}
.editReservationContainer.mini .editReservationDetails .dates {
    width: 20.89vw;
}
.editReservationContainer.mini .editReservationDetails .dates .DateRangePicker__picker {
    left: -5.9vw !important;
    top: 2.7vw;
}
.editReservationContainer.mini .dates > div:first-child {
    display: none;
}
.editReservationContainer.mini .DateRangePickerInput__arrow > div {
    line-height: 2.25vw;
}
.editReservationContainer.mini .DateRangePickerInput {
    background-color: #fff;
    height: 2.2vw;
    line-height: 2.2vw;
    border: 1px solid #ccc;
}
.editReservationContainer.mini .DateInput__display-text {
    line-height: 1.8vw;
    color: #333;
}
.editReservationContainer.mini .dates .arrow {
    border-color: #757575;
}
.editReservationContainer.mini .editReservationDetails .updateRates {
    width: 9.5vw;
    height: 2.2vw;
    font-size: 1.5vw;
    line-height: 2.2vw;
}
.editReservationContainer.mini .editReservationDetails > div:last-child {
    width: 18.375vw;
    display: none;
}
.editReservationContainer.mini.fullMode .editReservationDetails > div:last-child {
    width: 18.375vw;
    display: block;
}
.editReservationContainer.mini .editReservationDetails .people {
    width: 100%;
    margin-bottom: 1vw;
}
.editReservationContainer.mini .editReservationDetails .people .options {
    width: 100%;
}
.editReservationContainer.mini .editReservationDetails .people .moreOptions {
    border: none;
    padding-bottom: 0;
}

@media screen and (max-width: 767px) {
    .editReservationContainer {
        max-height: 1000vw;
        height: inherit;
        flex-direction: column;
        overflow: inherit;
        padding-left: 7.2vw;
    }
    .editReservationContainer > div:first-child {
        width: 100%;
    }
    .editReservationHeader {
        font-size: 5vw;
        line-height: 12.9vw;
        letter-spacing: 0;
    }
    .editReservationDetails {
        width: 84.8vw;
        margin-top: 2.4vw;
    }
    .editReservationDetails .hotelName {
        margin-bottom: 6.5vw;
    }
    .editReservationDetails > div {
        margin-bottom: 4.9vw;
    }
    .editReservationDetails > div.people {
        margin-bottom: 9vw;
    }
    .dates {
        position: relative;
    }
    .dates .errors-block {
        flex-direction: column;
    }
    .dates .errors-empty {
        margin-top: 4vw;
    }
    .dates .arrow {
        padding: .9vw;
    }
    .dates > div.errors-block .errors {
        height: 4vw;
        line-height: 8vw;
    }
    .dates > div.errors-block > span:first-child {
        width: 100%;
    }
    .DateRangePickerInput {
        height: 8.5vw;
    }
    .DateRangePicker {
        width: 100%;
    }
    .DateInput {
        font-size: 3.5vw;
        line-height: 7vw;
        width: 40.2vw;
        height: 10.2vw;
    }
    .DateInput__display-text {
        padding-left: 4vw;
        line-height: 7.8vw;
        font-size: 4vw;
    }
    .DateRangePicker__picker {
        top: 10.5vw;
    }
    .editReservationContainer .CalendarDay__button {
        font-size: 4vw;
    }
    .editReservationContainer .selectBox {
        height: 10.5vw;
        font-size: 4vw;
    }
    .people .options {
        width: 100%;
    }
    .people .room > div {
        font-size: 2.5vw;
    }
    .updateRates {
        font-size: 4.8vw;
        line-height: 8vw;
        font-weight: 700;
        padding: 1vw 0;
        height: 10.2vw;
    }
    .transition-container.transition-container--horizontal:after {
        display: none;
    }
    .DayPicker--horizontal {
        border-radius: 0;
    }
    .DayPicker__week-header li small {
        font-size: 4vw;
        margin-left: 2vw/*rtl:0; */;
    }
    td.CalendarDay button:hover:after, td.CalendarDay button:hover:before {
        content: none !important;
    }

    .calendarInfo .menu > span {
        width: 33%;
        font-size: 3vw;
    }
    .calendarInfo .menu .square {
        height: 3vw;
        width: 3vw;
    }
    .editReservationContainer .Select-control {
        height: 10.5vw;
    }
    .editReservationContainer .Select-arrow-zone {
        padding-right: 3.5vw;
    }
    .editReservationContainer .Select-value, .editReservationContainer .Select-placeholder {
        line-height: 9.5vw !important;
        font-size: 4vw;
        padding-left: 4vw !important;
        letter-spacing: 0.2vw;
    }
    .editReservationContainer .Select-menu-outer {
        line-height: 6vw !important;
        font-size: 4vw;
        padding-left: 4vw !important;
        letter-spacing: 0.2vw;
    }
    .editReservationContainer .Select-value-label {
        line-height: 6.5vw;
    }
    .editReservationContainer .Select-input {
        height: 6.5vw;
    }
    .hotels .Select-option {
        padding: 0.1vw 10px;
    }
    .people .moreOptions {
        display: flex;
        flex-direction: column;
        border-bottom: none;
    }
    .people .room {
        display: block;
        width: 100%;
    }
    .people .room:first-child {
        width: 100%;
        margin: 5.5vw 0;
    }
    .people .room > div {
        float: left;
        width: 31.5%;
    }
    .people .room > div:first-child {
        width: 100%;
        font-size: 4vw;
        font-weight: bold;
    }
    .people .number {
        width: 90%;
    }
    .people .room > div:last-child {
        width: 5%;
        line-height: 18vw;
    }
    .editReservationContainer .clubSticky {
        width: 95%;
        margin: auto;
        height: initial;
        display: none;
    }
    .editReservationContainer .clubSticky > div > div.edanBtns > a {
        font-size: 5vw;
        padding: 3vw;
    }
    .editReservationContainer .clubSticky .iconsRow .bgImg {
        width: 9vw;
        height: 9vw;
    }
    .editReservationContainer .clubSticky .iconsRow .txt {
        font-size: 2.5vw;
    }
    .editReservationContainer .clubSticky > div > div.title {
        font-size: 6.5vw;
        font-weight: 100;
    }
    /*-------------------mini-------------------*/
    .editReservationContainer.mini {
        padding-bottom: 9vw;
        padding-left: 7.2vw;
        padding-top: 3.25vw
    }
    .editReservationContainer.mini .editReservationHeader {
        font-size: 5vw;
        line-height: 12.9vw;
        letter-spacing: 0;
    }

    .editReservationContainer.mini.closed {
        padding-bottom: 16.5vw;
    }
    .editReservationContainer.mini.closed .editReservationHeader {
        position: relative;
    }
    .editReservationContainer.mini.closed .editReservationHeader .arrow {
        display: block;
        position: absolute;
        top: 5vw;
        right: -5.1vw;
        border: solid #feffff;
        /*rtl:begin:ignore*/
        border-width: 0 0.2vw 0.2vw 0;
        /*rtl:end:ignore*/
    }

    .editReservationContainer.mini .editReservationDetails {
        width: 84.8vw;
    }
    .editReservationContainer.mini.closed .editReservationDetails {
        display: none;
    }
    .editReservationContainer.mini .editReservationDetails > div:first-child {
        flex-direction: column;
    }
    .editReservationContainer.mini .editReservationDetails .hotels {
        width: 100%;
        margin-bottom: 8.5vw;
    }
    .editReservationContainer.mini .editReservationDetails .dates {
        width: 100%;
        margin-bottom: 6.5vw;
    }
    .editReservationContainer.mini .DateRangePickerInput {
        height: 7.2vw;
        background-color: transparent;
        border: none;
    }
    .editReservationContainer.mini .DateInput__display-text {
        line-height: 7.8vw;
        font-size: 4vw;
    }
    .editReservationContainer.mini .editReservationDetails .dates .DateRangePicker__picker {
        left: 0 !important;
    }
    .dates .DayPickerNavigation__prev, .dates .DayPickerNavigation__next {
        height: 5vw;
        width: 5vw;
    }
    .dates .arrow {
        position: absolute;
        top: 1.4vw;
        /*rtl:begin:ignore*/
        left: 1.5vw;
        /*rtl:end:ignore*/
    }
    .editReservationContainer.mini .editReservationDetails .updateRates {
        width: 100%;
        height: 10.2vw;
        font-size: 4.8vw;
        line-height: 8.2vw;
    }
    .editReservationContainer.mini .editReservationDetails > div:last-child {
        width: 100%;
    }
    .editReservationContainer.mini .editReservationDetails .people {
        margin-bottom: 6vw;
    }
    .editReservationContainer.mini.fullMode .editReservationDetails > div:last-child {
        width: 100%;
    }

}